
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { userForgetPassword } from '../../api/forgetPasswordApi';
import { getGooleConfig } from '../../api/googleReCaptchaApi';

export const useForgetPassword = props => {

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [clientReady, setClientReady] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [reCaptchaKey, setReCaptchaKey] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [reCaptchaEnable, setReCaptchaEnable] = useState(false);

    const getConfig = useCallback((values) => {
        getGooleConfig(values).then(res => {
            setReCaptchaKey(res.response.reCaptchaKey);
            res.items.forEach((item) => {
                if (item.key === 'recaptcha.is_enable') {
                    setReCaptchaEnable(Number(item.value));
                }
                if (item.key === 'recaptcha.site_key') {
                    setReCaptchaKey(item.value);
                }
            })

        }).catch(error => {
            console.log('error', error);

        })
    })


    useEffect(() => {
        setClientReady(true);
        getConfig()
    }, []);

    const onStartLogin = useCallback((values) => {
        setLoginLoading(true);
        setShowErrorMsg(false);
        userForgetPassword({
            ...values,
            recaptchaToken: recaptchaToken
        }).then(res => {
            setLoginLoading(false);
            if (res && res.response.isSuccess) {
                navigate('/resetpassword', { replace: true })
            }
            else {
                setShowErrorMsg(true);
                setErrorMsg(res.response.errorMessage);
            }
        }).catch(error => {
            setShowErrorMsg(true);
            setErrorMsg(error.message);
            console.error(error)
            setLoginLoading(false);
        })

    }, [navigate, form, recaptchaToken, refreshReCaptcha]);

    useEffect(() => {
        const userLocalData = localStorage.getItem("userLocalData");
        if (userLocalData) {
            const userSaveData = JSON.parse(userLocalData);
            if (userSaveData && userSaveData.remember) {
                form.setFieldsValue(userSaveData)
            }
        }
    }, []);

    const handleNavigateBack = useCallback(() => {
        navigate('/login', { replace: false })
    })

    const handleCloseMessage = useCallback(() => {
        setShowErrorMsg(false);
    })

    const handleReCaptchaVerify = useCallback(async (token) => {
        setRecaptchaToken(token)
    }, []);


    return {
        form,
        clientReady,
        loginLoading,
        onStartLogin,
        errorMsg,
        showErrorMsg,
        setShowErrorMsg,
        handleNavigateBack,
        handleCloseMessage,
        reCaptchaKey,
        handleReCaptchaVerify,
        refreshReCaptcha,
        recaptchaToken,
        reCaptchaEnable
    };

}