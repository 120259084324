
import { Routes, Route, Navigate, useLocation, useRoutes } from "react-router-dom";

import React, { Suspense } from 'react';
// import { Navigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import Login from '../pages/Login/login';
import PasswordForgot from '../pages/PasswordForgot/passwordForgot';
import PasswordReset from '../pages/PasswordReset/passwordReset';
import PasswordResetSuccess from '../pages/PasswordResetSuccess/passwordResetSuccess';

import Homepage from '../pages/Homepage/homePage';
import RouterAuth from '../components/RouterAuth/routerAuth';
import RouterTitle from '../components/RouterTitle/routerTitle';

import PageNotFound from '../components/PageNotFound/pageNotFound';

const Dashboard = React.lazy(() => import('../components/DashboardPage/dashboard'));
// const Customer = React.lazy(() => import('../components/CustomerData/customerData'));
// const Reports = React.lazy(() => import('../components/ReportPage/report'));
const UserManage = React.lazy(() => import('../components/UserManage/userManage'));
const Merchants = React.lazy(() => import('../components/Merchants/merchants'));
// const MerchantService = React.lazy(() => import('../components/MerchantService/merchantService'));
// const MerchantUsers = React.lazy(() => import('../components/MerchantUsers/merchantUsers'));
const ServiceAlias = React.lazy(() => import('../components/ServiceAlias/serviceAlias'));
// const MerchantServiceInstance = React.lazy(() => import('../components/MerchantServiceInstance/merchantServiceInstance'));
const ServicePlan = React.lazy(() => import('../components/ServicePlan/servicePlan'));
const AdminRole = React.lazy(() => import('../components/AdminRole/adminRole'));
const SystemOperationLog = React.lazy(() => import('../components/SystemOperationLog/systemOperationLog'));
// const MerchantVendor = React.lazy(() => import('../components/MerchantVendor/merchantVendor'));
const Settings = React.lazy(() => import('../components/Settings/settings'));
const FrontendUsers = React.lazy(() => import('../components/FrontendUsers/frontendUsers'));

// 实现懒加载的用Suspense包裹 定义函数
const lazyLoad = (children) => {
  return <Suspense fallback={<h1>Loading...</h1>}>
    {children}
  </Suspense>
}


const Routers = props => {

  const { formatMessage } = useIntl();

  const routes = [
    {
      path: '/',
      element: <Navigate to="/dashboard" />,
      auth: false
    },
    {
      path: '/index',
      element: <Navigate to="/dashboard" />,
      auth: false
    },
    {
      path: '/login',
      element: <RouterTitle
        title={formatMessage({ id: 'router.login', defaultMessage: 'Login' })}
      >
        <Login />
      </RouterTitle>,

    },
    {
      path: '/forgetpassword',
      element: <RouterTitle
        title={formatMessage({ id: 'router.forgetpassword', defaultMessage: 'Forget password' })}
      >
        <PasswordForgot />
      </RouterTitle>,

    },
    {
      path: '/resetpassword',
      element: <RouterTitle
        title={formatMessage({ id: 'router.resetpassword', defaultMessage: 'Reset password' })}
      >
        <PasswordReset />
      </RouterTitle>

    },
    {
      path: '/resetpassword/success',
      element: <RouterTitle
        title={formatMessage({ id: 'router.passwordResetSuccess', defaultMessage: 'Reset password success' })}
      >
        <PasswordResetSuccess />
      </RouterTitle>,

    },
    {
      path: '/',
      element: <Homepage />,
      auth: true,
      children: [
        {
          path: "*",
          element: <Navigate to="/page-not-found" />
        },
        {
          path: "/page-not-found",
          element: <RouterAuth
            title={formatMessage({ id: 'router.dashboard', defaultMessage: 'dashboard' })}
            permissionCode="">
            <PageNotFound />
          </RouterAuth>
        },
        {
          path: '/dashboard',
          element: <RouterAuth
            title={formatMessage({ id: 'router.dashboard', defaultMessage: 'dSashboard' })}
            permissionCode="dashboard">
            {lazyLoad(<Dashboard />)}
          </RouterAuth>

        },
        {
          path: '/admin-role',
          element: <RouterAuth
            title={formatMessage({ id: 'router.adminRole', defaultMessage: 'Admin Role' })}
            permissionCode="permission/admin_role/list">
            {lazyLoad(<AdminRole />)}
          </RouterAuth>

        },
        {
          path: '/users',
          element: <RouterAuth
            title={formatMessage({ id: 'router.users', defaultMessage: 'Users' })}
            permissionCode="permission/admin_user/list">
            {lazyLoad(<UserManage />)}
          </RouterAuth>
        },
        {
          path: '/merchants',
          element: <RouterAuth
            title={formatMessage({ id: 'router.merchants', defaultMessage: 'Merchants' })}
            permissionCode="permission/merchant/list">
            {lazyLoad(<Merchants />)}
          </RouterAuth>

        },
        // {
        //   path: '/merchants-vendor',
        //   element: <RouterAuth
        //     title={formatMessage({ id: 'router.merchantsVendor', defaultMessage: 'Merchants Vendor' })}
        //     permissionCode="permission/merchant/list">
        //     {lazyLoad(<MerchantVendor />)}
        //   </RouterAuth>
        // },
        {
          path: '/service-alias',
          element: <RouterAuth
            title={formatMessage({ id: 'router.serviceAlias', defaultMessage: 'Service Alias' })}
            permissionCode="permission/service/list">
            {lazyLoad(<ServiceAlias />)}
          </RouterAuth>

        },
        {
          path: '/service-plan',
          element: <RouterAuth
            title={formatMessage({ id: 'router.servicePlan', defaultMessage: 'Service Plan' })}
            permissionCode="permission/service_plan/list">
            {lazyLoad(<ServicePlan />)}
          </RouterAuth>,
        },
        {
          path: '/system-operation-log',
          element: <RouterAuth
            title={formatMessage({ id: 'router.operationLog', defaultMessage: 'Operation Log' })}
            permissionCode="permission/logs/operation_log_list">
            {lazyLoad(<SystemOperationLog />)}
          </RouterAuth>,

        },
        {
          path: '/frontend-users',
          element: <RouterAuth
            title={formatMessage({ id: 'router.FrontendUsers', defaultMessage: 'Frontend Users' })}
            permissionCode="">
            {lazyLoad(<FrontendUsers />)}
          </RouterAuth>,

        },

        {
          path: '/account/settings',
          element: <RouterAuth
            title={formatMessage({ id: 'router.account', defaultMessage: 'Account' })}
            permissionCode="">
            {lazyLoad(<Settings />)}
          </RouterAuth>,
        },

      ]
    },


  ]


  const elements = useRoutes(routes)

  return elements
}

export default Routers