
const zh_CN = {
    "global.webName": "e-Com365.com",
    "global.searchBtn": "查询",
    "global.addBtn": "新增",
    "global.backBtn": "返回",
    "global.male": "男",
    "global.female": "女",
    "global.apiError": "未知错误，请重试",
    "global.apiEditSuccess": "修改成功!",
    "global.apiaddSuccess": "创建成功!",
    "global.apidelSuccess": "删除成功!",
    "global.apisendSuccess": "发送成功!",
    "global.confirmDelModalTitle": "Delete Items",
    "global.systemLandingTitle":"Welcome back to Merchant portal",
    "global.systemLandingMessage":"Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed qu",
    "global.copyright":"Copyright @ 2024 BCNetcom. All Rights Reserved",
    
    "form.isRequired": "{name}是必填项!",
    "form.isValid": "请输入有效的{name}",
    "form.select": "请选择",
    "form.cancel": "取消",
    "form.ok": "保存",
    "form.delete": "Delete",
    "form.okBtn": "OK",

    "Login.panelName": "登录",
    "Login.required_userName": "请输入用户名",
    "Login.input_username": "用户名",
    "Login.required_password": "请输入密码",
    "Login.input_password": "密码",
    "Login.required_email":"请输入密码电邮",
    "Login.input_email":"电邮",
    "Login.loginBtn": "登录",
    "Login.panelRightSpan": "Don’t have an account?",
    "Login.panelRightSpanButton": "Register a new account",
    "Login.rememberMeLabel": "记住密码",
    "Login.forgetBtn": "忘记密码？",
    
    "forgetPassword.passwordReset":"Password Reset",
    "forgetPassword.panelRightSpan":"We Will Help You Reset your Password",
    "forgetPassword.rememberedPassword":"Remembered your Password?",

    "DashboardHeader.labelTips": "Let’s get creative with e-Com365 Go CRM Micro-service to organize your content.",

    "homeMenu.dashboard": "首页",
    "homeMenu.customer": "用户管理",
    "homeMenu.allCustomers": "All Customers",
    "homeMenu.campaign": "Campaign",
    "homeMenu.campaigns": "Campaigns",
    "homeMenu.campaignSettings": "Settings",
    "homeMenu.reports": "Reports",
    "homeMenu.main": "首页",
    "homeMenu.push": "消息推送记录",
    "homeMenu.company": "公司管理",
    "homeMenu.role": "角色管理",
    "homeMenu.user": "管理员",
    "homeUser.loginout": "退出登录",

    "Customer.pushBtn": "推送消息",
    "Customer.pushPromoBtn": "推送优惠",
    "Customer.findBtnLabel": "Find",
    "Customer.chooseCompany": "请点击右边按钮选择公司",
    "Customer.addCustomerTitle": "新增用户信息",
    "Customer.editCustomerTitle": "编辑用户信息",
    "Customer.detailCustomerTitle": "Detail Customer",
    "Customer.selectCompanyTitle": "选择公司",
    "Customer.editPushMessage": "配置推送消息",
    "Customer.pushModal.okBtn": "发送",
    "customer.confirmModalDelMsg": "确认删除id等于{val}的用户信息？",
    "customer.selectCompanyWarning": "请选择公司!",
    "customer.confirmModalPromoMsg": "确认向{val}位用户推送促销信息？",
    "customer.addFirstCustomerTitle": "Add First Customer",
    "customer.addFirstCustomerLabel1": "Having a customer list helps to know your audience better.",
    "customer.addFirstCustomerLabel2": "Manage your customers in one place and target your audinece better.",
    "customer.addFirstCustomer": "Add Customer",
    "customer.addFirstCustomerReadMore": "Read More",
    
    "createCustomer.backLabel": "Back",
    
    "detailCustomer.editInfoButton": "Edit information",
    
    "campaigns.addNewCampaignLabel": "Create New Campaigns",
    "campaigns.saveButton": "Save Campaign",
    "campaigns.editCampaignLabel": "Edit The Campaign",
    "campaigns.sendCampaignLabel": "Send Campaign",
    
    "exports.exportButton": "Export",
    "exports.customerGrowth": "Customer Growth",
    "exports.salesGoal": "Sales Goal",
    "exports.conversionRate": "Conversion Rate",
    "exports.storeFunnel": "Store Funnel",
    "exports.ageDistribution": "Age Distribution",
    "exports.averageOrderValue": "Average Order Value",

    "company.addCompanyTitle": "新增公司信息",
    "company.editCompanyTitle": "编辑公司信息",
    "company.confirmModalDelMsg": "确认删除id等于{val}的公司信息？",
    
    "Message.PushDetailTitle": "消息详情",
    "Message.PushTargetTitle": "消息推送目标",
    "Message.notFoundDetail": "未知的消息",
    "MessageModal.text_isRequired": "请输入内容或移除本输入框",

    "pushModal.addTextBtn": "添加新文本",
    "pushModal.addImageBtn": "上传图片",
    "pushModal.addImagePlaceHolder": "输入图片链接或上传图片(png/jpg/jpeg), 多条链接用[ , ]分隔",
    "pushModal.uploadImageTypeError": "{name}不是有效的图片类型",
    "pushModal.addVideoBtn": "上传视频",
    "pushModal.addVideoPlaceHolder": "输入视频链接或上传视频(mp4/avi/mov/flv), 多条链接用[ , ]分隔",
    "pushModal.uploadVideoTypeError": "{name}不是有效的视频类型",
    "pushModal.addDocumentBtn": "上传文档",
    "pushModal.addDocumentPlaceHolder": "输入文档链接或上传文档(doc/docx/pdf/xls/xlsx/ppt/pptx), 多条链接用[ , ]分隔",
    "pushModal.uploadDocumentTypeError": "{name}不是有效的文档类型",
    "pushModal.uploadSize": "文件最大不得超过30M",

    "role.addRoleTitle": "Create Role",
    "role.editRoleTitle": "Edit Role",
    "role.confirmModalDelMsg": "Are you sure to delete role data with RoleName equal {val}?",

    "user.addUserTitle": "Create User",
    "user.editUserTitle": "Edit User",
    "user.confirmModalDelMsg": "Are you sure to delete user data with Uid equal {val}?",
}
export {
    zh_CN
}   