// dark
export function IconAddDarkSvg() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.34998 8.64998V12.5H8.64998V8.64998H12.5V7.34998H8.64998V3.5H7.34998V7.34998H3.5V8.64998H7.34998Z" fill="black" fillOpacity="0.6" />
        </svg>
    )
}


// white

export function IconUserWhiteSvg() {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.00003 1C6.52575 1 7.02232 1.12482 7.46171 1.34645L6.83144 2.1586C6.57424 2.05626 6.2937 2 6.00003 2C4.75739 2 3.75003 3.00736 3.75003 4.25C3.75003 5.47717 4.73246 6.47488 5.95373 6.49953V7.49968C4.18015 7.47491 2.75003 6.02947 2.75003 4.25C2.75003 2.45507 4.2051 1 6.00003 1Z" fill="white" />
        <path d="M0.540929 9.43593C2.18917 8.66419 4.01922 8.22779 5.95373 8.22094V9.22095C4.18421 9.22774 2.51071 9.62379 1 10.3252V12H2.85709V13H0.5C0.223857 13 0 12.7761 0 12.5V10.3086C0 9.9372 0.204537 9.59344 0.540929 9.43593Z" fill="white" />
        <path d="M9.99992 8.31354C8.20499 8.31354 6.74992 6.85846 6.74992 5.06354C6.74992 3.26861 8.20499 1.81354 9.99992 1.81354C11.7948 1.81354 13.2499 3.26861 13.2499 5.06354C13.2499 6.85846 11.7948 8.31354 9.99992 8.31354ZM9.99992 7.31354C11.2426 7.31354 12.2499 6.30618 12.2499 5.06354C12.2499 3.8209 11.2426 2.81354 9.99992 2.81354C8.75728 2.81354 7.74992 3.8209 7.74992 5.06354C7.74992 6.30618 8.75728 7.31354 9.99992 7.31354Z" fill="white" />
        <path d="M16 11.5178C16 11.1464 15.7954 10.8026 15.459 10.6451C13.7977 9.86723 11.9516 9.43005 9.99997 9.43005C8.04837 9.43005 6.20227 9.86723 4.54089 10.6451C4.2045 10.8026 3.99996 11.1464 3.99996 11.5178V14C3.99996 14.2761 4.22382 14.5 4.49996 14.5H15.5C15.7761 14.5 16 14.2761 16 14V11.5178ZM15 11.5344V13.5H4.99996V11.5344C6.52384 10.8269 8.21334 10.43 9.99997 10.43C11.7866 10.43 13.4761 10.8269 15 11.5344Z" fill="white" />
    </svg>)
}

// blue
export function IconEditBulueSvg() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.881 1.73689L14.1243 4.98027L14.8314 4.27316L11.5881 1.02979L10.881 1.73689Z" fill="#0F6CBD" />
            <path d="M2.35119 13.8632L5.96415 13.1406L13.2618 5.84298L10.0184 2.5996L2.72077 9.89721L1.99818 13.5102C1.9562 13.7201 2.14127 13.9052 2.35119 13.8632ZM10.0184 4.01382L11.8475 5.84298L5.47114 12.2194L3.18468 12.6767L3.64197 10.3902L10.0184 4.01382Z" fill="#0F6CBD" />
        </svg>
    )
}

export function IconDeleteBulueSvg() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 12V6H7V12H6Z" fill="#0F6CBD" />
            <path d="M9 6V12H10V6H9Z" fill="#0F6CBD" />
            <path d="M10.5 3H14V4H13V14C13 14.5523 12.5523 15 12 15H4C3.44772 15 3 14.5523 3 14V4H2V3H5.5L5.5 1.8C5.5 1.35817 5.85817 1 6.3 1H9.7C10.1418 1 10.5 1.35817 10.5 1.8V3ZM6.5 3H9.5L9.5 2L6.5 2V3ZM4 4V14H12V4H4Z" fill="#0F6CBD" />
        </svg>
    )
}

// grey
export function IconUserGreySvg() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9375 5.625C12.9375 7.79962 11.1746 9.5625 9 9.5625C6.82538 9.5625 5.0625 7.79962 5.0625 5.625C5.0625 3.45038 6.82538 1.6875 9 1.6875C11.1746 1.6875 12.9375 3.45038 12.9375 5.625ZM11.8125 5.625C11.8125 4.0717 10.5533 2.8125 9 2.8125C7.4467 2.8125 6.1875 4.0717 6.1875 5.625C6.1875 7.1783 7.4467 8.4375 9 8.4375C10.5533 8.4375 11.8125 7.1783 11.8125 5.625Z" fill="black" fillOpacity="0.4" />
            <path d="M15.7085 12.2094C16.0841 12.3888 16.3125 12.774 16.3125 13.1902V15.75C16.3125 16.0607 16.0607 16.3125 15.75 16.3125H2.25C1.93934 16.3125 1.6875 16.0607 1.6875 15.75V13.1902C1.6875 12.774 1.91589 12.3888 2.29147 12.2094C4.33058 11.2355 6.59982 10.6875 9 10.6875C11.4002 10.6875 13.6694 11.2355 15.7085 12.2094ZM9 11.8125C6.78758 11.8125 4.69614 12.3139 2.8125 13.2073V15.1875H15.1875V13.2073C13.3039 12.3139 11.2124 11.8125 9 11.8125Z" fill="black" fillOpacity="0.4" />
        </svg>
    )
}


export function IconPasswordLockGreySvg() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.75 11.25V12.375H11.25V11.25H6.75Z" fill="black" fillOpacity="0.4" />
            <path d="M5.0625 5.61469V6.74997H3.375C3.06434 6.74997 2.8125 7.00181 2.8125 7.31247V15.1875C2.8125 15.4981 3.06434 15.75 3.375 15.75H14.625C14.9357 15.75 15.1875 15.4981 15.1875 15.1875V7.31247C15.1875 7.00181 14.9357 6.74997 14.625 6.74997H12.9375V5.61469C12.9375 3.44006 11.1746 1.67719 9 1.67719C6.82538 1.67719 5.0625 3.44006 5.0625 5.61469ZM11.8125 6.74997H6.1875V5.61469C6.1875 4.06138 7.4467 2.80219 9 2.80219C10.5533 2.80219 11.8125 4.06138 11.8125 5.61469V6.74997ZM3.9375 7.87497H14.0625V14.625H3.9375V7.87497Z" fill="black" fillOpacity="0.4" />
        </svg>
    )
}

export const IconPasswordVisibleGreySvg = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1011_17554)">
        <path d="M12.2396 9C12.2396 10.7893 10.7891 12.2399 8.99973 12.2399C7.21033 12.2399 5.75986 10.7893 5.75986 9C5.75986 7.21074 7.21033 5.7602 8.99973 5.7602C10.7891 5.7602 12.2396 7.21074 12.2396 9ZM11.1146 9C11.1146 7.83202 10.1676 6.8852 8.99973 6.8852C7.8316 6.8852 6.88486 7.83202 6.88486 9C6.88486 10.168 7.8316 11.1149 8.99973 11.1149C10.1676 11.1149 11.1146 10.168 11.1146 9Z" fill="black" fillOpacity="0.6" />
        <path d="M1.25656 9.25873C2.7691 12.2287 5.73926 14.0625 9.00549 14.0625C12.2607 14.0625 15.2309 12.2287 16.7434 9.25873L16.875 9L16.7434 8.74127C15.2309 5.78252 12.2607 3.9375 9.00549 3.9375C5.73926 3.9375 2.7691 5.78252 1.25656 8.74127L1.125 9L1.25656 9.25873ZM9.00549 12.9375C6.24353 12.9375 3.73343 11.4412 2.37442 9C3.73343 6.55877 6.24353 5.0625 9.00549 5.0625C11.7565 5.0625 14.2666 6.55877 15.6366 9C14.2773 11.4412 11.7565 12.9375 9.00549 12.9375Z" fill="black" fillOpacity="0.6" />
    </g>
    <defs>
        <clipPath id="clip0_1011_17554">
            <rect width="18" height="18" fill="white" />
        </clipPath>
    </defs>
</svg>


export const IconPasswordHideGreySvg = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1198 13.4778L13.6648 15.0227L14.4602 14.2272L3.5434 3.31036L2.74799 4.10591L4.10999 5.46784C2.93088 6.28357 1.94348 7.39752 1.25656 8.74108L1.125 8.9998L1.25656 9.25853C2.7691 12.2285 5.73926 14.0623 9.00549 14.0623C10.0898 14.0623 11.1423 13.8588 12.1198 13.4778ZM11.2401 12.598C10.5268 12.8202 9.77536 12.9373 9.00549 12.9373C6.24353 12.9373 3.73343 11.441 2.37442 8.9998C3.00366 7.86938 3.87982 6.94165 4.91336 6.27121L6.13229 7.49021C5.89444 7.941 5.75986 8.45468 5.75986 8.9998C5.75986 10.7891 7.21033 12.2397 8.99973 12.2397C9.54492 12.2397 10.0585 12.105 10.5092 11.8672L11.2401 12.598ZM6.98785 8.34584L9.65369 11.0116C9.44769 11.0785 9.22797 11.1147 8.99973 11.1147C7.8316 11.1147 6.88486 10.1678 6.88486 8.9998C6.88486 8.77156 6.92084 8.55177 6.98785 8.34584Z" fill="black" fillOpacity="0.4" />
    <path d="M16.7434 9.25853L16.875 8.9998L16.7434 8.74108C15.2309 5.78232 12.2607 3.9373 9.00549 3.9373C8.04227 3.9373 7.10458 4.09777 6.22266 4.40003L7.12271 5.30023C7.72888 5.14395 8.36032 5.0623 9.00549 5.0623C11.7565 5.0623 14.2666 6.55857 15.6366 8.9998C15.0631 10.0297 14.2831 10.8914 13.366 11.5436L14.163 12.3404C15.2213 11.5464 16.1101 10.5021 16.7434 9.25853Z" fill="black" fillOpacity="0.4" />
    <path d="M12.2396 8.9998C12.2396 9.42044 12.1594 9.82234 12.0135 10.1911L11.097 9.27446C11.1086 9.18458 11.1146 9.09284 11.1146 8.9998C11.1146 7.83182 10.1676 6.885 8.99973 6.885C8.90662 6.885 8.81488 6.89098 8.72507 6.90265L7.80853 5.98598C8.17712 5.84013 8.57922 5.76 8.99973 5.76C10.7891 5.76 12.2396 7.21054 12.2396 8.9998Z" fill="black" fillOpacity="0.4" />
</svg>


export function IconFilterDarkSvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 13H12.5C12.7761 13 13 13.2239 13 13.5C13 13.7455 12.8231 13.9496 12.5899 13.9919L12.5 14H7.5C7.22386 14 7 13.7761 7 13.5C7 13.2545 7.17688 13.0504 7.41012 13.0081L7.5 13H12.5H7.5ZM5.5 9H14.5C14.7761 9 15 9.22386 15 9.5C15 9.74546 14.8231 9.94961 14.5899 9.99194L14.5 10H5.5C5.22386 10 5 9.77614 5 9.5C5 9.25454 5.17688 9.05039 5.41012 9.00806L5.5 9H14.5H5.5ZM3.5 5H16.5C16.7761 5 17 5.22386 17 5.5C17 5.74546 16.8231 5.94961 16.5899 5.99194L16.5 6H3.5C3.22386 6 3 5.77614 3 5.5C3 5.25454 3.17688 5.05039 3.41012 5.00806L3.5 5H16.5H3.5Z" fill="#424242" />
    </svg>
    )
}

export function IconFilterBlueSvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 13H12.5C12.7761 13 13 13.2239 13 13.5C13 13.7455 12.8231 13.9496 12.5899 13.9919L12.5 14H7.5C7.22386 14 7 13.7761 7 13.5C7 13.2545 7.17688 13.0504 7.41012 13.0081L7.5 13H12.5H7.5ZM5.5 9H14.5C14.7761 9 15 9.22386 15 9.5C15 9.74546 14.8231 9.94961 14.5899 9.99194L14.5 10H5.5C5.22386 10 5 9.77614 5 9.5C5 9.25454 5.17688 9.05039 5.41012 9.00806L5.5 9H14.5H5.5ZM3.5 5H16.5C16.7761 5 17 5.22386 17 5.5C17 5.74546 16.8231 5.94961 16.5899 5.99194L16.5 6H3.5C3.22386 6 3 5.77614 3 5.5C3 5.25454 3.17688 5.05039 3.41012 5.00806L3.5 5H16.5H3.5Z" fill="#0F6CBD" />
    </svg>)
}


// menu sidebar

export function IconDashboardGraySvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.0625 2.1875C11.9914 2.1875 10.3125 3.86643 10.3125 5.9375C10.3125 8.00857 11.9914 9.6875 14.0625 9.6875C16.1336 9.6875 17.8125 8.00857 17.8125 5.9375C17.8125 3.86643 16.1336 2.1875 14.0625 2.1875ZM11.5625 5.9375C11.5625 4.55679 12.6818 3.4375 14.0625 3.4375C15.4432 3.4375 16.5625 4.55679 16.5625 5.9375C16.5625 7.31821 15.4432 8.4375 14.0625 8.4375C12.6818 8.4375 11.5625 7.31821 11.5625 5.9375Z" fill="black" fillOpacity="0.6" />
        <path d="M2.5 3.75C2.5 3.05964 3.05964 2.5 3.75 2.5H8.125C8.81536 2.5 9.375 3.05964 9.375 3.75V8.125C9.375 8.81536 8.81536 9.375 8.125 9.375H3.75C3.05964 9.375 2.5 8.81536 2.5 8.125V3.75ZM3.75 3.75V8.125H8.125V3.75H3.75Z" fill="black" fillOpacity="0.6" />
        <path d="M2.5 11.875C2.5 11.1846 3.05964 10.625 3.75 10.625H8.125C8.81536 10.625 9.375 11.1846 9.375 11.875V16.25C9.375 16.9404 8.81536 17.5 8.125 17.5H3.75C3.05964 17.5 2.5 16.9404 2.5 16.25V11.875ZM3.75 11.875V16.25H8.125V11.875H3.75Z" fill="black" fillOpacity="0.6" />
        <path d="M10.625 11.875C10.625 11.1846 11.1846 10.625 11.875 10.625H16.25C16.9404 10.625 17.5 11.1846 17.5 11.875V16.25C17.5 16.9404 16.9404 17.5 16.25 17.5H11.875C11.1846 17.5 10.625 16.9404 10.625 16.25V11.875ZM11.875 16.25H16.25V11.875H11.875V16.25Z" fill="black" fillOpacity="0.6" />
    </svg>)
}

export function IconDashboardBlueSvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.0625 2.1875C11.9914 2.1875 10.3125 3.86643 10.3125 5.9375C10.3125 8.00857 11.9914 9.6875 14.0625 9.6875C16.1336 9.6875 17.8125 8.00857 17.8125 5.9375C17.8125 3.86643 16.1336 2.1875 14.0625 2.1875ZM11.5625 5.9375C11.5625 4.55679 12.6818 3.4375 14.0625 3.4375C15.4432 3.4375 16.5625 4.55679 16.5625 5.9375C16.5625 7.31821 15.4432 8.4375 14.0625 8.4375C12.6818 8.4375 11.5625 7.31821 11.5625 5.9375Z" fill="#0F6CBD" />
        <path d="M2.5 3.75C2.5 3.05964 3.05964 2.5 3.75 2.5H8.125C8.81536 2.5 9.375 3.05964 9.375 3.75V8.125C9.375 8.81536 8.81536 9.375 8.125 9.375H3.75C3.05964 9.375 2.5 8.81536 2.5 8.125V3.75ZM3.75 3.75V8.125H8.125V3.75H3.75Z" fill="#0F6CBD" />
        <path d="M2.5 11.875C2.5 11.1846 3.05964 10.625 3.75 10.625H8.125C8.81536 10.625 9.375 11.1846 9.375 11.875V16.25C9.375 16.9404 8.81536 17.5 8.125 17.5H3.75C3.05964 17.5 2.5 16.9404 2.5 16.25V11.875ZM3.75 11.875V16.25H8.125V11.875H3.75Z" fill="#0F6CBD" />
        <path d="M10.625 11.875C10.625 11.1846 11.1846 10.625 11.875 10.625H16.25C16.9404 10.625 17.5 11.1846 17.5 11.875V16.25C17.5 16.9404 16.9404 17.5 16.25 17.5H11.875C11.1846 17.5 10.625 16.9404 10.625 16.25V11.875ZM11.875 16.25H16.25V11.875H11.875V16.25Z" fill="#0F6CBD" />
    </svg>)
}

export function IconMenuUserGreySvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.50004 1.25C8.15719 1.25 8.7779 1.40603 9.32713 1.68307L8.5393 2.69825C8.21779 2.57033 7.86713 2.5 7.50004 2.5C5.94673 2.5 4.68753 3.7592 4.68753 5.3125C4.68753 6.84646 5.91558 8.0936 7.44216 8.12442V9.3746C5.22518 9.34364 3.43753 7.53683 3.43753 5.3125C3.43753 3.06884 5.25638 1.25 7.50004 1.25Z" fill="black" fillOpacity="0.6" />
        <path d="M0.676162 11.7949C2.73647 10.8302 5.02403 10.2847 7.44216 10.2762V11.5262C5.23026 11.5347 3.13839 12.0297 1.25 12.9065V15H3.57136V16.25H0.624999C0.279821 16.25 0 15.9701 0 15.625V12.8858C0 12.4215 0.255671 11.9918 0.676162 11.7949Z" fill="black" fillOpacity="0.6" />
        <path d="M12.4999 10.3919C10.2562 10.3919 8.4374 8.57308 8.4374 6.32942C8.4374 4.08577 10.2562 2.26692 12.4999 2.26692C14.7436 2.26692 16.5624 4.08577 16.5624 6.32942C16.5624 8.57308 14.7436 10.3919 12.4999 10.3919ZM12.4999 9.14192C14.0532 9.14192 15.3124 7.88272 15.3124 6.32942C15.3124 4.77612 14.0532 3.51692 12.4999 3.51692C10.9466 3.51692 9.6874 4.77612 9.6874 6.32942C9.6874 7.88272 10.9466 9.14192 12.4999 9.14192Z" fill="black" fillOpacity="0.6" />
        <path d="M20 14.3973C20 13.933 19.7443 13.5033 19.3238 13.3064C17.2471 12.334 14.9395 11.7876 12.5 11.7876C10.0605 11.7876 7.75284 12.334 5.67611 13.3064C5.25562 13.5033 4.99995 13.933 4.99995 14.3973V17.5C4.99995 17.8452 5.27977 18.125 5.62495 18.125H19.375C19.7201 18.125 20 17.8452 20 17.5V14.3973ZM18.75 14.418V16.875H6.24995V14.418C8.1548 13.5336 10.2667 13.0376 12.5 13.0376C14.7332 13.0376 16.8451 13.5336 18.75 14.418Z" fill="black" fillOpacity="0.6" />
    </svg>)
}

export function IconMenuUserBlueSvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.50004 1.25C8.15719 1.25 8.7779 1.40603 9.32713 1.68307L8.5393 2.69825C8.21779 2.57033 7.86713 2.5 7.50004 2.5C5.94673 2.5 4.68753 3.7592 4.68753 5.3125C4.68753 6.84646 5.91558 8.0936 7.44216 8.12442V9.3746C5.22518 9.34364 3.43753 7.53683 3.43753 5.3125C3.43753 3.06884 5.25638 1.25 7.50004 1.25Z" fill="#0F6CBD" />
        <path d="M0.676162 11.7949C2.73647 10.8302 5.02403 10.2847 7.44216 10.2762V11.5262C5.23026 11.5347 3.13839 12.0297 1.25 12.9065V15H3.57136V16.25H0.624999C0.279821 16.25 0 15.9701 0 15.625V12.8858C0 12.4215 0.255671 11.9918 0.676162 11.7949Z" fill="#0F6CBD" />
        <path d="M12.4999 10.3919C10.2562 10.3919 8.4374 8.57308 8.4374 6.32942C8.4374 4.08577 10.2562 2.26692 12.4999 2.26692C14.7436 2.26692 16.5624 4.08577 16.5624 6.32942C16.5624 8.57308 14.7436 10.3919 12.4999 10.3919ZM12.4999 9.14192C14.0532 9.14192 15.3124 7.88272 15.3124 6.32942C15.3124 4.77612 14.0532 3.51692 12.4999 3.51692C10.9466 3.51692 9.6874 4.77612 9.6874 6.32942C9.6874 7.88272 10.9466 9.14192 12.4999 9.14192Z" fill="#0F6CBD" />
        <path d="M20 14.3973C20 13.933 19.7443 13.5033 19.3238 13.3064C17.2471 12.334 14.9395 11.7876 12.5 11.7876C10.0605 11.7876 7.75284 12.334 5.67611 13.3064C5.25562 13.5033 4.99995 13.933 4.99995 14.3973V17.5C4.99995 17.8452 5.27977 18.125 5.62495 18.125H19.375C19.7201 18.125 20 17.8452 20 17.5V14.3973ZM18.75 14.418V16.875H6.24995V14.418C8.1548 13.5336 10.2667 13.0376 12.5 13.0376C14.7332 13.0376 16.8451 13.5336 18.75 14.418Z" fill="#0F6CBD" />
    </svg>)
}


export function IconMerchantGreySvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 13.125C11.5516 13.125 13.0276 13.5157 14.375 14.2244V15.625L15.625 15.625V14.198C15.625 13.7633 15.4016 13.3552 15.0181 13.1505C13.4866 12.3332 11.7907 11.875 10 11.875C8.20935 11.875 6.51344 12.3332 4.98188 13.1505C4.59841 13.3552 4.375 13.7633 4.375 14.198V15.625H5.625V14.2244C6.97242 13.5157 8.44845 13.125 10 13.125Z" fill="black" fillOpacity="0.6" />
        <path d="M13.125 7.5C13.125 9.22589 11.7259 10.625 10 10.625C8.27411 10.625 6.875 9.22589 6.875 7.5C6.875 5.77411 8.27411 4.375 10 4.375C11.7259 4.375 13.125 5.77411 13.125 7.5ZM11.875 7.5C11.875 6.46447 11.0355 5.625 10 5.625C8.96447 5.625 8.125 6.46447 8.125 7.5C8.125 8.53553 8.96447 9.375 10 9.375C11.0355 9.375 11.875 8.53553 11.875 7.5Z" fill="black" fillOpacity="0.6" />
        <path d="M3.125 1.875C2.43464 1.875 1.875 2.43464 1.875 3.125V16.875C1.875 17.5654 2.43464 18.125 3.125 18.125H16.875C17.5654 18.125 18.125 17.5654 18.125 16.875V3.125C18.125 2.43464 17.5654 1.875 16.875 1.875H3.125ZM16.875 3.125V16.875H3.125L3.125 3.125H16.875Z" fill="black" fillOpacity="0.6" />
    </svg>)
}

export function IconMerchantBlueSvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 13.125C11.5516 13.125 13.0276 13.5157 14.375 14.2244V15.625L15.625 15.625V14.198C15.625 13.7633 15.4016 13.3552 15.0181 13.1505C13.4866 12.3332 11.7907 11.875 10 11.875C8.20935 11.875 6.51344 12.3332 4.98188 13.1505C4.59841 13.3552 4.375 13.7633 4.375 14.198V15.625H5.625V14.2244C6.97242 13.5157 8.44845 13.125 10 13.125Z" fill="#0F6CBD" />
        <path d="M13.125 7.5C13.125 9.22589 11.7259 10.625 10 10.625C8.27411 10.625 6.875 9.22589 6.875 7.5C6.875 5.77411 8.27411 4.375 10 4.375C11.7259 4.375 13.125 5.77411 13.125 7.5ZM11.875 7.5C11.875 6.46447 11.0355 5.625 10 5.625C8.96447 5.625 8.125 6.46447 8.125 7.5C8.125 8.53553 8.96447 9.375 10 9.375C11.0355 9.375 11.875 8.53553 11.875 7.5Z" fill="#0F6CBD" />
        <path d="M3.125 1.875C2.43464 1.875 1.875 2.43464 1.875 3.125V16.875C1.875 17.5654 2.43464 18.125 3.125 18.125H16.875C17.5654 18.125 18.125 17.5654 18.125 16.875V3.125C18.125 2.43464 17.5654 1.875 16.875 1.875H3.125ZM16.875 3.125V16.875H3.125L3.125 3.125H16.875Z" fill="#0F6CBD" />
    </svg>)
}

export function IconMerchantServiceGreySvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.25 6.875C11.25 8.94608 9.57092 10.625 7.5 10.625C5.42908 10.625 3.75 8.94608 3.75 6.875C3.75 4.80392 5.42908 3.125 7.5 3.125C9.57092 3.125 11.25 4.80392 11.25 6.875ZM10 6.875C10 5.49429 8.88062 4.375 7.5 4.375C6.11938 4.375 5 5.49429 5 6.875C5 8.25571 6.11938 9.375 7.5 9.375C8.88062 9.375 10 8.25571 10 6.875Z" fill="black" fillOpacity="0.6" />
        <path d="M17.5 12.5C17.5 14.2259 16.1008 15.625 14.375 15.625C12.6492 15.625 11.25 14.2259 11.25 12.5C11.25 10.7741 12.6492 9.375 14.375 9.375C16.1008 9.375 17.5 10.7741 17.5 12.5ZM16.25 12.5C16.25 11.4645 15.4105 10.625 14.375 10.625C13.3395 10.625 12.5 11.4645 12.5 12.5C12.5 13.5355 13.3395 14.375 14.375 14.375C15.4105 14.375 16.25 13.5355 16.25 12.5Z" fill="black" fillOpacity="0.6" />
        <path d="M6.25 15C6.25 16.0355 5.41046 16.875 4.375 16.875C3.33954 16.875 2.5 16.0355 2.5 15C2.5 13.9645 3.33954 13.125 4.375 13.125C5.41046 13.125 6.25 13.9645 6.25 15Z" fill="black" fillOpacity="0.6" />
        <path d="M16.25 5.625C16.9403 5.625 17.5 5.06536 17.5 4.375C17.5 3.68464 16.9403 3.125 16.25 3.125C15.5597 3.125 15 3.68464 15 4.375C15 5.06536 15.5597 5.625 16.25 5.625Z" fill="black" fillOpacity="0.6" />
    </svg>)
}

export function IconMerchantServiceBlueSvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.25 6.875C11.25 8.94608 9.57092 10.625 7.5 10.625C5.42908 10.625 3.75 8.94608 3.75 6.875C3.75 4.80392 5.42908 3.125 7.5 3.125C9.57092 3.125 11.25 4.80392 11.25 6.875ZM10 6.875C10 5.49429 8.88062 4.375 7.5 4.375C6.11938 4.375 5 5.49429 5 6.875C5 8.25571 6.11938 9.375 7.5 9.375C8.88062 9.375 10 8.25571 10 6.875Z" fill="#0F6CBD" fillOpacity="0.6" />
        <path d="M17.5 12.5C17.5 14.2259 16.1008 15.625 14.375 15.625C12.6492 15.625 11.25 14.2259 11.25 12.5C11.25 10.7741 12.6492 9.375 14.375 9.375C16.1008 9.375 17.5 10.7741 17.5 12.5ZM16.25 12.5C16.25 11.4645 15.4105 10.625 14.375 10.625C13.3395 10.625 12.5 11.4645 12.5 12.5C12.5 13.5355 13.3395 14.375 14.375 14.375C15.4105 14.375 16.25 13.5355 16.25 12.5Z" fill="#0F6CBD" fillOpacity="0.6" />
        <path d="M6.25 15C6.25 16.0355 5.41046 16.875 4.375 16.875C3.33954 16.875 2.5 16.0355 2.5 15C2.5 13.9645 3.33954 13.125 4.375 13.125C5.41046 13.125 6.25 13.9645 6.25 15Z" fill="#0F6CBD" fillOpacity="0.6" />
        <path d="M16.25 5.625C16.9403 5.625 17.5 5.06536 17.5 4.375C17.5 3.68464 16.9403 3.125 16.25 3.125C15.5597 3.125 15 3.68464 15 4.375C15 5.06536 15.5597 5.625 16.25 5.625Z" fill="#0F6CBD" fillOpacity="0.6" />
    </svg>)
}

export function IconMerchantServiceInstanceGreySvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.99994 2.40239C4.99994 1.67158 5.65001 1.25 6.24412 1.25H11.7073C12.043 1.25 12.3645 1.385 12.5996 1.62461L17.1424 6.25518C17.3715 6.48866 17.4999 6.80262 17.5001 7.12969L17.5058 15.0972C17.5058 15.828 16.8557 16.25 16.2616 16.25H6.24994C5.6559 16.25 5.00593 15.8285 5.00576 15.0979L4.99994 2.40239ZM6.24999 2.5L6.25572 15H16.2557L16.2504 7.51594H11.25V2.5H6.24999ZM12.5 3.30806V6.26594H15.4019L12.5 3.30806Z" fill="black" fillOpacity="0.6" />
        <path d="M2.5 6.25002V17.5159C2.5 18.2063 3.05965 18.7659 3.75 18.7659H13.75V17.5159L3.75 17.5159V6.25002H2.5Z" fill="black" fillOpacity="0.6" />
    </svg>
    )
}

export function IconMerchantServiceInstanceBlueSvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.99994 2.40239C4.99994 1.67158 5.65001 1.25 6.24412 1.25H11.7073C12.043 1.25 12.3645 1.385 12.5996 1.62461L17.1424 6.25518C17.3715 6.48866 17.4999 6.80262 17.5001 7.12969L17.5058 15.0972C17.5058 15.828 16.8557 16.25 16.2616 16.25H6.24994C5.6559 16.25 5.00593 15.8285 5.00576 15.0979L4.99994 2.40239ZM6.24999 2.5L6.25572 15H16.2557L16.2504 7.51594H11.25V2.5H6.24999ZM12.5 3.30806V6.26594H15.4019L12.5 3.30806Z" fill="#0F6CBD" fillOpacity="0.6" />
        <path d="M2.5 6.25002V17.5159C2.5 18.2063 3.05965 18.7659 3.75 18.7659H13.75V17.5159L3.75 17.5159V6.25002H2.5Z" fill="#0F6CBD" fillOpacity="0.6" />
    </svg>
    )
}


export function IconServiceAliasGreySvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.74969 6.5625H4.99969V5.3125H8.74969V6.5625Z" fill="black" fillOpacity="0.6" />
        <path d="M1.875 3.12517C1.87491 2.77993 2.15476 2.5 2.5 2.5H17.5C17.8451 2.5 18.1249 2.77972 18.125 3.12483L18.1262 8.74985C18.1263 9.0951 17.8464 9.37502 17.5012 9.37502H2.50119C2.15608 9.37502 1.87628 9.0953 1.87619 8.75019L1.875 3.12517ZM3.12602 8.12502H16.876L16.8752 3.75H3.12517L3.12602 8.12502Z" fill="black" fillOpacity="0.6" />
        <path d="M4.99983 14.6875H8.74983V13.4375H4.99983V14.6875Z" fill="black" fillOpacity="0.6" />
        <path d="M1.87636 11.25C1.87636 10.9048 2.15618 10.625 2.50136 10.625H17.5014C17.8465 10.625 18.1264 10.9048 18.1264 11.25V16.875C18.1264 17.2202 17.8465 17.5 17.5014 17.5H2.50136C2.15618 17.5 1.87636 17.2202 1.87636 16.875V11.25ZM3.12636 16.25H16.8764V11.875H3.12636V16.25Z" fill="black" fillOpacity="0.6" />
    </svg>
    )
}


export function IconServiceAliasBlueSvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.74969 6.5625H4.99969V5.3125H8.74969V6.5625Z" fill="#0F6CBD" />
        <path d="M1.875 3.12517C1.87491 2.77993 2.15476 2.5 2.5 2.5H17.5C17.8451 2.5 18.1249 2.77972 18.125 3.12483L18.1262 8.74985C18.1263 9.0951 17.8464 9.37502 17.5012 9.37502H2.50119C2.15608 9.37502 1.87628 9.0953 1.87619 8.75019L1.875 3.12517ZM3.12602 8.12502H16.876L16.8752 3.75H3.12517L3.12602 8.12502Z" fill="#0F6CBD" />
        <path d="M4.99983 14.6875H8.74983V13.4375H4.99983V14.6875Z" fill="#0F6CBD" />
        <path d="M1.87636 11.25C1.87636 10.9048 2.15618 10.625 2.50136 10.625H17.5014C17.8465 10.625 18.1264 10.9048 18.1264 11.25V16.875C18.1264 17.2202 17.8465 17.5 17.5014 17.5H2.50136C2.15618 17.5 1.87636 17.2202 1.87636 16.875V11.25ZM3.12636 16.25H16.8764V11.875H3.12636V16.25Z" fill="#0F6CBD" />
    </svg>
    )
}


export function IconServicePlanGreySvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 9.375L13.75 9.375V10.625L5 10.625V9.375Z" fill="black" fillOpacity="0.6" />
        <path d="M12.5 12.5L5 12.5V13.75H12.5V12.5Z" fill="black" fillOpacity="0.6" />
        <path d="M2.5 3.75C2.5 3.05964 3.05964 2.5 3.75 2.5H16.25C16.9404 2.5 17.5 3.05964 17.5 3.75V16.25C17.5 16.9404 16.9404 17.5 16.25 17.5H3.75C3.05964 17.5 2.5 16.9404 2.5 16.25V3.75ZM3.75 3.75L3.75 6.25H16.25V3.75H3.75ZM3.75 7.5L3.75 16.25H16.25V7.5H3.75Z" fill="black" fillOpacity="0.6" />
    </svg>
    )
}

export function IconServicePlanBlueSvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 9.375L13.75 9.375V10.625L5 10.625V9.375Z" fill="#0F6CBD" />
        <path d="M12.5 12.5L5 12.5V13.75H12.5V12.5Z" fill="#0F6CBD" />
        <path d="M2.5 3.75C2.5 3.05964 3.05964 2.5 3.75 2.5H16.25C16.9404 2.5 17.5 3.05964 17.5 3.75V16.25C17.5 16.9404 16.9404 17.5 16.25 17.5H3.75C3.05964 17.5 2.5 16.9404 2.5 16.25V3.75ZM3.75 3.75L3.75 6.25H16.25V3.75H3.75ZM3.75 7.5L3.75 16.25H16.25V7.5H3.75Z" fill="#0F6CBD" />
    </svg>
    )
}


export function IconPermissionGreySvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path d="M10 11.25C11.9192 11.25 13.475 9.69419 13.475 7.775C13.475 5.85581 11.9192 4.3 10 4.3C8.08081 4.3 6.525 5.85581 6.525 7.775C6.525 9.69419 8.08081 11.25 10 11.25ZM10 10C8.77117 10 7.775 9.00383 7.775 7.775C7.775 6.54617 8.77117 5.55 10 5.55C11.2288 5.55 12.225 6.54617 12.225 7.775C12.225 9.00383 11.2288 10 10 10Z" fill="black" fillOpacity="0.6" />
            <path d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM16.2443 14.1557C14.3435 13.0956 12.2319 12.5 9.99997 12.5C7.76802 12.5 5.65649 13.0956 3.75564 14.1557C2.96238 12.9661 2.5 11.5371 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 11.5371 17.0376 12.9662 16.2443 14.1557ZM15.454 15.1482C14.0867 16.5963 12.1489 17.5 10 17.5C7.85112 17.5 5.91332 16.5963 4.54593 15.1482C6.22562 14.247 8.06603 13.75 9.99997 13.75C11.9339 13.75 13.7743 14.247 15.454 15.1482Z" fill="black" fillOpacity="0.6" />
        </g>
    </svg>

    )
}


export function IconPermissionBlueSvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path d="M10 11.25C11.9192 11.25 13.475 9.69419 13.475 7.775C13.475 5.85581 11.9192 4.3 10 4.3C8.08081 4.3 6.525 5.85581 6.525 7.775C6.525 9.69419 8.08081 11.25 10 11.25ZM10 10C8.77117 10 7.775 9.00383 7.775 7.775C7.775 6.54617 8.77117 5.55 10 5.55C11.2288 5.55 12.225 6.54617 12.225 7.775C12.225 9.00383 11.2288 10 10 10Z" fill="#0F6CBD" />
            <path d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM16.2443 14.1557C14.3435 13.0956 12.2319 12.5 9.99997 12.5C7.76802 12.5 5.65649 13.0956 3.75564 14.1557C2.96238 12.9661 2.5 11.5371 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 11.5371 17.0376 12.9662 16.2443 14.1557ZM15.454 15.1482C14.0867 16.5963 12.1489 17.5 10 17.5C7.85112 17.5 5.91332 16.5963 4.54593 15.1482C6.22562 14.247 8.06603 13.75 9.99997 13.75C11.9339 13.75 13.7743 14.247 15.454 15.1482Z" fill="#0F6CBD" />
        </g>
    </svg>

    )
}


export function IconViewDetailBlueSvg() {
    return (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1011_17554)">
            <path d="M12.2396 9C12.2396 10.7893 10.7891 12.2399 8.99973 12.2399C7.21033 12.2399 5.75986 10.7893 5.75986 9C5.75986 7.21074 7.21033 5.7602 8.99973 5.7602C10.7891 5.7602 12.2396 7.21074 12.2396 9ZM11.1146 9C11.1146 7.83202 10.1676 6.8852 8.99973 6.8852C7.8316 6.8852 6.88486 7.83202 6.88486 9C6.88486 10.168 7.8316 11.1149 8.99973 11.1149C10.1676 11.1149 11.1146 10.168 11.1146 9Z" fill="#0F6CBD" />
            <path d="M1.25656 9.25873C2.7691 12.2287 5.73926 14.0625 9.00549 14.0625C12.2607 14.0625 15.2309 12.2287 16.7434 9.25873L16.875 9L16.7434 8.74127C15.2309 5.78252 12.2607 3.9375 9.00549 3.9375C5.73926 3.9375 2.7691 5.78252 1.25656 8.74127L1.125 9L1.25656 9.25873ZM9.00549 12.9375C6.24353 12.9375 3.73343 11.4412 2.37442 9C3.73343 6.55877 6.24353 5.0625 9.00549 5.0625C11.7565 5.0625 14.2666 6.55877 15.6366 9C14.2773 11.4412 11.7565 12.9375 9.00549 12.9375Z" fill="#0F6CBD" />
        </g>
        <defs>
            <clipPath id="clip0_1011_17554">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>)
}

export function IconEmailDarkSvg() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 5C1.5 4.44772 1.94772 4 2.5 4H13.5C14.0523 4 14.5 4.44772 14.5 5V13C14.5 13.5523 14.0523 14 13.5 14H2.5C1.94772 14 1.5 13.5523 1.5 13V5ZM13.0986 5H2.90139L8 8.39907L13.0986 5ZM2.5 5.93426L2.5 13H13.5V5.93426L8 9.60093L2.5 5.93426Z" fill="black" fillOpacity="0.9" />
        </svg>

    )
}

export function IconPhoneDarkSvg() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9996 11.057C14.0094 11.6559 13.8406 12.3012 13.4825 12.8171L13.3562 12.984C13.1203 13.3294 12.7802 13.5821 12.3763 13.7332C12.0957 13.8381 11.92 13.8685 11.467 13.9205C9.51866 14.1027 7.38954 13.1933 5.28719 11.323C3.8828 10.071 2.83116 8.50499 2.32479 6.90403C2.11109 6.22702 1.99984 5.57663 1.99984 4.98798C1.99984 4.28214 2.15924 3.67073 2.47982 3.21147C2.78396 2.79374 3.36194 2.44674 4.23086 2.11878C4.65696 1.95794 5.13791 2.10536 5.40072 2.47733L7.06606 4.83476C7.34085 5.22375 7.30316 5.75255 6.97604 6.09867L6.7593 6.32011L6.49703 6.5743C6.43153 6.6297 6.33187 6.76327 6.33187 6.76327C6.30907 7.06999 6.63615 7.70105 7.58846 8.67556L7.87185 8.94138C8.75003 9.75129 8.98642 9.81626 9.25619 9.62839L9.34609 9.55496C9.44154 9.48235 9.69138 9.30846 10.2242 8.94214C10.5298 8.73201 10.9267 8.7083 11.2551 8.88056L11.4976 9.0088C13.1606 9.89658 13.9203 10.4309 13.9937 10.979L13.9996 11.057ZM12.9997 11.073C13.001 11.1552 12.8854 11.0286 12.6447 10.8548C12.2936 10.6013 11.7532 10.2789 11.0283 9.89185L10.7907 9.76617L10.0027 10.31C9.98437 10.3219 9.97594 10.3267 9.97927 10.3228C9.11992 10.9978 8.37978 10.8085 6.88619 9.38723C5.74004 8.21483 5.28626 7.33935 5.33425 6.69444C5.35666 6.34154 5.59986 6.01838 5.8359 5.82497C5.9363 5.72841 6.03513 5.63132 6.12417 5.54105L6.24929 5.41174L4.58401 3.05435C3.88251 3.31913 3.4487 3.57953 3.29391 3.79204C3.10764 4.05906 2.99984 4.47276 2.99984 4.98798C2.99984 5.46839 3.09399 6.01871 3.27834 6.60274C3.72853 8.02606 4.67843 9.44064 5.95223 10.5762C7.86462 12.2775 9.73829 13.0778 11.3635 12.9259L11.621 12.8938C11.799 12.8685 11.8995 12.8438 12.0261 12.7965C12.2452 12.7145 12.4181 12.5861 12.5589 12.3819C12.8261 12.0556 12.9688 11.638 12.9954 11.2417L12.9997 11.073Z" fill="black" fillOpacity="0.9" />
        </svg>
    )
}

export function IconBackReturnGreySvg() {
    return (
        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.3871 0.209705L4.29289 0.292893L0.292893 4.29289C-0.0675907 4.65338 -0.0953203 5.22061 0.209705 5.6129L0.292893 5.70711L4.29289 9.70711C4.68342 10.0976 5.31658 10.0976 5.70711 9.70711C6.06759 9.34662 6.09532 8.77939 5.7903 8.3871L5.70711 8.29289L3.414 5.999L12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4L3.416 3.999L5.70711 1.70711C6.06759 1.34662 6.09532 0.779392 5.7903 0.387101L5.70711 0.292893C5.34662 -0.0675907 4.77939 -0.0953203 4.3871 0.209705Z" fill="black" fillOpacity="0.4" />
        </svg>
    )
}

export function IconMenuSettingsGreySvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.7501 10C13.7501 12.0711 12.0712 13.75 10.0001 13.75C7.92902 13.75 6.25009 12.0711 6.25009 10C6.25009 7.92893 7.92902 6.25 10.0001 6.25C12.0712 6.25 13.7501 7.92893 13.7501 10ZM12.5001 10C12.5001 8.61929 11.3808 7.5 10.0001 7.5C8.61937 7.5 7.50009 8.61929 7.50009 10C7.50009 11.3807 8.61937 12.5 10.0001 12.5C11.3808 12.5 12.5001 11.3807 12.5001 10Z" fill="black" fillOpacity="0.6" />
        <path d="M10.0001 1.5625L17.5778 5.78125V14.2188L10.0001 18.4375L2.42236 14.2188V5.78125L10.0001 1.5625ZM3.67236 6.516V13.484L10.0001 17.0068L16.3278 13.484V6.516L10.0001 2.99316L3.67236 6.516Z" fill="black" fillOpacity="0.6" />
    </svg>)
}

export function IconMenuSettingsBlueSvg() {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.7501 10C13.7501 12.0711 12.0712 13.75 10.0001 13.75C7.92902 13.75 6.25009 12.0711 6.25009 10C6.25009 7.92893 7.92902 6.25 10.0001 6.25C12.0712 6.25 13.7501 7.92893 13.7501 10ZM12.5001 10C12.5001 8.61929 11.3808 7.5 10.0001 7.5C8.61937 7.5 7.50009 8.61929 7.50009 10C7.50009 11.3807 8.61937 12.5 10.0001 12.5C11.3808 12.5 12.5001 11.3807 12.5001 10Z" fill="#0F6CBD" />
        <path d="M10.0001 1.5625L17.5778 5.78125V14.2188L10.0001 18.4375L2.42236 14.2188V5.78125L10.0001 1.5625ZM3.67236 6.516V13.484L10.0001 17.0068L16.3278 13.484V6.516L10.0001 2.99316L3.67236 6.516Z" fill="#0F6CBD" />
    </svg>)
}
