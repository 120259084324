
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { userLogin, getProfile } from '../../api/loginApi';
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setIsLoginCompleted, setUserInfo } from '../../redux/userSlice'
import { getGooleConfig } from '../../api/googleReCaptchaApi';

export const useLogin = props => {

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const dispatch = useDispatch()

    const [clientReady, setClientReady] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [reCaptchaKey, setReCaptchaKey] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [reCaptchaEnable, setReCaptchaEnable] = useState(false);

    const getConfig = useCallback((values) => {
        getGooleConfig(values).then(res => {
            setReCaptchaKey(res.response.reCaptchaKey);
            res.items.forEach((item) => {
                if (item.key === 'recaptcha.is_enable') {
                    setReCaptchaEnable(Number(item.value));
                }
                if (item.key === 'recaptcha.site_key') {
                    setReCaptchaKey(item.value);
                }
            })

        }).catch(error => {
            console.log('error', error);

        })
    })


    useEffect(() => {
        localStorage.removeItem("ADMIN_LOGIN_TOKEN");
        setClientReady(true);
        getConfig()

    }, []);

    const onStartLogin = useCallback((values) => {
        setLoginLoading(true);
        userLogin({
            ...values,
            recaptchaToken: recaptchaToken
        }).then(res => {

            setLoginLoading(false);
            if (res && res.response.isSuccess) {
                localStorage.setItem("userLocalData", JSON.stringify({ email: values.email }));
                const token = {
                    timeStored: new Date().getTime(),
                    ttl: 86400,
                    value: res.token
                }
                localStorage.setItem("ADMIN_LOGIN_TOKEN", JSON.stringify(token));
                dispatch(setToken(res.token))

                // getProfile
                getProfile().then(resProfile => {
                    // console.log('resProfile', resProfile);
                    if (resProfile && resProfile.response.isSuccess) {
                        dispatch(setUserInfo(resProfile.user))
                        dispatch(setIsLoginCompleted(true))
                    } else {
                        dispatch(setIsLoginCompleted(true))
                        setShowErrorMsg(true);
                        setErrorMsg(resProfile.response.errorMessage);
                    }

                    navigate('/', { replace: true })

                }).catch(error => {
                    setShowErrorMsg(true);
                    setErrorMsg(error.message);
                    dispatch(setIsLoginCompleted(true))
                    setRefreshReCaptcha(!refreshReCaptcha);

                    console.error(error)
                })



            } else {
                setShowErrorMsg(true);
                setErrorMsg(res.response.errorMessage);
                setRefreshReCaptcha(!refreshReCaptcha);
            }
        }).catch(error => {
            console.error(error)
            setShowErrorMsg(true);
            setErrorMsg(error.message);
            setLoginLoading(false);
        })

    }, [navigate, form, recaptchaToken, refreshReCaptcha]);

    useEffect(() => {
        const userLocalData = localStorage.getItem("userLocalData");
        if (userLocalData) {
            const userSaveData = JSON.parse(userLocalData);
            if (userSaveData && userSaveData.remember) {
                form.setFieldsValue(userSaveData)
            }
        }
    }, []);


    const handleForgetPassWord = useCallback(() => {
        navigate('/forgetpassword', { replace: false })
    })

    const handleCloseMessage = useCallback(() => {
        setShowErrorMsg(false);
    })
    const handleReCaptchaVerify = useCallback(async (token) => {
        setRecaptchaToken(token)
    }, []);

    return {
        form,
        clientReady,
        loginLoading,
        onStartLogin,
        errorMsg,
        showErrorMsg,
        setShowErrorMsg,
        handleForgetPassWord,
        handleCloseMessage,
        reCaptchaKey,
        handleReCaptchaVerify,
        refreshReCaptcha,
        recaptchaToken,
        reCaptchaEnable
    };

}