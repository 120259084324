
const en_US = {
    "global.webName": "e-Com365 Merchant Platform Admin Portal",
    "global.filterBtn": "Filter",
    "global.addBtn": "Create",
    "global.backBtn": "Back",
    "global.male": "Male",
    "global.female": "Female",
    "global.apiError": "unknown error, please try again",
    "global.apiEditSuccess": "edit success!",
    "global.apiaddSuccess": "create success!",
    "global.apidelSuccess": "delete success!",
    "global.apisendSuccess": "send success!",
    "global.confirmDelModalTitle": "Delete Items",
    "global.systemLandingTitle": "Welcome back to admin portal",
    "global.systemLandingMessage": "e-Com365 provides you enterprise level turn key solution on E-Commerce, CRM, marketing automation and AI business analytics.",
    "global.copyright": "Copyright @ 2025 BCNetcom. All Rights Reserved",
    "global.confirmModalTitle": "Warming",
    "global.delete": "Delete",

    "form.isRequired": "{name} is required!",
    "form.isValid": "please input a valid {name}",
    "form.placeholder": "Please enter {name}",
    "form.select": "select",
    "form.cancel": "Cancel",
    "form.ok": "Save",
    "form.delete": "Delete",
    "form.okBtn": "OK",
    "form.email": "Email",
    "form.emailPlaceholder": "email",
    "form.password": "Password",
    "form.passwordPlaceholder": "password",
    "form.confirmPassword": "Confirm password",
    "form.confirmPasswordPlaceholder": "confirm password",
    "form.verificationCode": "Verification code",
    "form.verificationCodePlaceholder": "verification code",
    "form.firstnamePlaceholder": "firstname",
    "form.firstname": "First Name",
    "form.firstnamePlaceholder": "first name",
    "form.lastname": "Last Name",
    "form.lastnamePlaceholder": "last name",
    "form.role": "Role",
    "form.timeZone": "Time Zone",
    "form.reset": "Reset",
    "form.merchantCompany": "Merchant company",
    "form.company": "Company",
    "form.companyPlaceholder": "company",
    "form.merchantContactEmail": "Merchant contact email",
    "form.phone": "Phone",
    "form.phonePlaceholder": "phone",
    "form.merchantContactPhone": "Merchant contact phone",
    "form.vendorContactEmail": "Vendor contact email",
    "form.vendorContactPhone": "Vendor contact phone",
    "form.serviceCode": "Service code",
    "form.serviceCodePlaceholder": "Service code",
    "form.serviceName": "Service name",
    "form.serviceNamePlaceholder": "service name",
    "form.enabled": "enabled",
    "form.disabled": "disabled",
    "form.active": "Active",
    "form.year": "year",
    "form.month": "month",
    "form.day": "day",
    "form.planRangePlaceholder": "plan range",
    "form.planRange": "Plan range",
    "form.planName": "Plan name",
    "form.planNamePlaceholder": "plan name",
    "form.planRangeUnit": "Plan range unit",
    "form.serviceAlias": "Service alias",
    "form.planEndDate": "Plan end date",
    "form.planStartDate": "Plan start date",
    "form.planExtendDate": "Plan extend date",
    "form.merchantServiceId": "Merchant service",
    "form.serviceStartDate": "Service start date",
    "form.serviceEndDate": "Service end date",
    "form.confCode": "Conf code",
    "form.confCodePlaceholder": "conf code",
    "form.confName": "Conf name",
    "form.confNamePlaceholder": "conf name",
    "form.apiGateway": "Api gateway",
    "form.apiGatewayPlaceholder": "api gateway",
    "form.frontendApiGateway": "Frontend api gateway",
    "form.frontendApiGatewayPlaceholder": "frontend api gateway",
    "form.status": "Status",
    "form.roleName": "Role name",
    "form.roleNamePlaceholder": "role name ",
    "form.all": "All",
    "form.Username": "User name",
    "form.UsernamePlaceholder": "user name",
    "form.UserRoleName": "User role name",
    "form.UserRoleNamePlaceholder": "user role name",
    "form.merchantCode": "Merchant code",
    "form.merchantCodePlaceholder": "merchant code",
    "form.merchantFrontendUrl": "Merchant frontend url",
    "form.merchantFrontendUrlPlaceholder": "merchant frontend url",
    "form.vendorMerchantId": "Vendor merchant",
    "form.merchantId": "Merchant",
    "form.vendorCode": "Vendor code",
    "form.vendorCodePlaceholder": "vendor code",
    "form.vendorName": "Vendor name",
    "form.vendorNamePlaceholder": "vendor name",
    "form.isMarketplace": "Is market place",
    "form.currentPasswordPlaceholder": "current password",
    "form.currentPassword": "Current Password",
    "form.newPassword": "New Password",
    "form.newPasswordPlaceholder": "new password",
    "form.email": "User email",
    "form.lastName": "Last name",
    "form.firstName": "First name",
    "form.phoneArea": "Country Code",
    "form.isForSubscribe": "Is for subscribe",
    "form.isSuperAdmin": "Is super admin",
    "form.merchantIncrementId": "Increment Id",
    "form.incrementIdPlaceholder": "increment id",

    "login.panelName": "Login",
    "login.loginBtn": "Login",
    "login.panelRightSpan": "Don’t have an account?",
    "login.panelRightSpanButton": "Register a new account",
    "login.rememberMeLabel": "Remember me",
    "login.forgetBtn": "Forget password ?",

    "passwordForget.passwordReset": "Password Reset",
    "passwordForget.panelRightSpan": "We Will Help You Reset your Password",
    "passwordForget.rememberedPassword": "Remembered your Password?",
    "passwordForget.navigateBack": "Back to Sign In",
    "passwordForget.label_code": "Verification Code",
    "passwordForget.placeholder_code": "Verification Code",

    "passwordReset.passwordReset": "Password Reset",
    "passwordReset.panelRightSpan": "We Will Help You Reset your Password",
    "passwordReset.rememberedPassword": "Remembered your Password?",
    "passwordReset.navigateBack": "Back to Sign In",
    "passwordReset.passwordNotMatch": "The new password that you entered do not match!",

    "passwordResetSuccess.successful": "Successful",
    "passwordResetSuccess.panelRightSpan": "The password has been changed successfully",
    "passwordResetSuccess.navigateBack": "Back to Sign In",

    "homeMenu.dashboard": "Dashboard",
    "homeMenu.customer": "Customer",
    "homeMenu.allCustomers": "All Customers",
    "homeMenu.campaign": "Campaign",
    "homeMenu.campaigns": "Campaigns",
    "homeMenu.campaignSettings": "Settings",
    "homeMenu.reports": "Reports",
    "homeMenu.main": "Main",
    "homeMenu.push": "Push",
    "homeMenu.company": "Company",
    "homeMenu.role": "Role",
    "homeMenu.user": "Users",
    "homeUser.loginout": "Sign Out",
    "homeUser.profile": "Profile",
    "homeMenu.allUsers": "Users",
    "homeMenu.users": "Users",
    "homeMenu.merchants": "Merchants",
    "homeMenu.merchantUsers": "Merchant Users",
    "homeMenu.merchantService": "Merchant Service",
    "homeMenu.serviceAlias": "Service",
    "homeMenu.serviceInstance": "Service Instance",
    "homeMenu.servicePlan": "Service Plan",
    "homeMenu.adminRole": "Admin Role",
    "homeMenu.operationLog": "Operation Log",
    "homeMenu.merchantsVendor": "Merchants Vendor",
    "homeMenu.account": "Account",

    "router.dashboard": "Dashboard",
    "router.users": "Users",
    "router.merchants": "Merchants",
    "router.merchantUsers": "Merchant Users",
    "router.merchantService": "Merchant Service",
    "router.serviceAlias": "Service Alias",
    "router.serviceInstance": "Service Instance",
    "router.servicePlan": "Service Plan",
    "router.adminRole": "Admin Role",
    "router.operationLog": "Operation Log",
    "router.PageNotFound": "Page Not Found",
    "router.login": "Login",
    "router.forgetpassword": "Forget Password",
    "router.resetpassword": "Reset Password",
    "router.passwordResetSuccess": "Reset Password Success",
    "router.merchantsVendor": "Merchants Vendor",
    "router.account": "Account",
    "router.FrontendUsers": "Frontend Users",
    "homeMenu.frontendUsers": "Frontend Users",

    "pageNotFound.pageNotFound": "Page Not Found",

    "DashboardHeader.labelTips": "Let’s get creative with e-Com365 Go CRM Micro-service to organize your content.",
    "DashboardHeader.panelLeftTitle": "Welcome back to e-Com365 Go CRM Micro-service",

    "Customer.pushBtn": "Push Message",
    "Customer.pushPromoBtn": "Push Promotion",
    "Customer.findBtnLabel": "Find",
    "Customer.chooseCompany": "click the button to select company",
    "Customer.addCustomerTitle": "Create Customer",
    "Customer.editCustomerTitle": "Edit Customer",
    "Customer.detailCustomerTitle": "Detail Customer",
    "Customer.selectCompanyTitle": "Select Company",
    "Customer.editPushMessage": "set push message",
    "Customer.pushModal.okBtn": "Send",
    "customer.confirmModalDelMsg": "Are you sure to delete {name} with {key} equal {val}?",
    "customer.selectCompanyWarning": "please select company!",
    "customer.confirmModalPromoMsg": "Are you sure to push promotion message to these {val} users?",
    "customer.addFirstCustomerTitle": "Add First Customer",
    "customer.addFirstCustomerLabel1": "Having a customer list helps to know your audience better.",
    "customer.addFirstCustomerLabel2": "Manage your customers in one place and target your audinece better.",
    "customer.addFirstCustomer": "Add Customer",
    "customer.addFirstCustomerReadMore": "Read More",

    "createCustomer.backLabel": "Back",

    "detailCustomer.editInfoButton": "Edit information",

    "campaigns.addNewCampaignLabel": "Create New Campaigns",
    "campaigns.saveButton": "Save Campaign",
    "campaigns.editCampaignLabel": "Edit The Campaign",
    "campaigns.sendCampaignLabel": "Send Campaign",

    "exports.exportButton": "Export",
    "exports.customerGrowth": "Customer Growth",
    "exports.salesGoal": "Sales Goal",
    "exports.conversionRate": "Conversion Rate",
    "exports.storeFunnel": "Store Funnel",
    "exports.ageDistribution": "Age Distribution",
    "exports.averageOrderValue": "Average Order Value",

    "Message.PushDetailTitle": "Push Detail",
    "Message.PushTargetTitle": "Push Target",
    "Message.notFoundDetail": "Message Not Found",
    "MessageModal.text_isRequired": "Please input a text or delete this field.",

    "pushModal.addTextBtn": "add text",
    "pushModal.addImageBtn": "Upload Image",
    "pushModal.addImagePlaceHolder": "input the image link or upload the file(png/jpg/jpeg), multiple links are separated by [ , ]",
    "pushModal.uploadImageTypeError": "{name} is not a valid image",
    "pushModal.addVideoBtn": "Upload Video",
    "pushModal.addVideoPlaceHolder": "input the video link or upload the file(mp4/avi/mov/flv), multiple links are separated by [ , ]",
    "pushModal.uploadVideoTypeError": "{name} is not a valid video",
    "pushModal.addDocumentBtn": "Upload Document",
    "pushModal.addDocumentPlaceHolder": "input the document link or upload the file(doc/docx/pdf/xls/xlsx/ppt/pptx), multiple links are separated by [ , ]",
    "pushModal.uploadDocumentTypeError": "{name} is not a valid document",
    "pushModal.uploadSize": "max size cannot exceed 30MB",

    "userManage.users": "Users",
    "userManage.role": "Role",
    "userManage.email": "Email",
    "userManage.createAt": "Account Created",
    "userManage.updatedAt": "Last Modified",
    "userManage.action": "Action",
    'userManage.addUserTitle': "Create User",
    'userManage.editUserTitle': "Edit User",
    "userManage.addBtn": "Add one user",
    "userManage.manageRolePermissionsBtn": "Manage role permissions",
    "userManage.totalsSummary": "Showing {from} to {to} of {total} results",
    "userManage.filterBtn": "Filter",
    "userManage.first_name": "First Name",
    "userManage.last_name": "Last Name",
    "userManage.email": "Email",
    "userManage.role_id": "Role",
    "userManage.activeFilters": "Active Filters:",
    "userManage.deleteUser": "Delete User",
    "userManage.confirmModalDelMsg": "Are you sure to delete user data with email equal {email}?",

    "merchants.addBtn": "Create New Merchant",
    "merchants.filterBtn": "Filter",
    "merchants.activeFilters": "Active Filters:",
    'merchants.addMerchantTitle': "Create Merchant",
    'merchants.editMerchantTitle': "Edit Merchant",
    "merchants.merchants": "Merchant Name",
    "merchants.merchantCode": "Merchant Code",
    "merchants.merchantFrontendUrl": "Merchant Frontend Url",
    "merchants.timeZone": "",
    "merchants.email": "Email",
    "merchants.merchantContactPhone": "Phone",
    "merchants.createdAt": "Account Created",
    "merchants.updatedAt": "Last Modified",
    "merchants.action": "Action",
    "merchants.totalsSummary": "Showing {from} to {to} of {total} results",
    "merchants.merchant_company": "Merchant company",
    "merchants.merchant_code": "Merchant company",
    "merchants.merchant_contact_email": "Email",
    "merchants.merchant_contact_phone": "Phone",
    "merchants.increment_id": "Id",
    "merchants.timeZone": "Time Zone",
    "merchants.deleteMerchant": "Delete Merchant",
    "merchants.confirmModalDelMsg": "Are you sure to delete merchant data with email equal {email}?",

    "merchantUsers.users": "Users",
    "merchantUsers.role": "Role",
    "merchantUsers.allowService": "Permission",
    "merchantUsers.permissionTitle": "{userName}'s permission",
    "merchantUsers.email": "Email",
    "merchantUsers.createdAt": "Account Created",
    "merchantUsers.updatedAt": "Last Modified",
    "merchantUsers.action": "Action",
    'merchantUsers.addUserTitle': "Invite User",
    'merchantUsers.editUserTitle': "Edit User",
    "merchantUsers.addBtn": "Add one user",
    "merchantUsers.manageRolePermissionsBtn": "Manage role permissions",
    "merchantUsers.totalsSummary": "Showing {from} to {to} of {total} results",
    "merchantUsers.filterBtn": "Filter",
    "merchantUsers.first_name": "Firstname",
    "merchantUsers.last_name": "Lastname",
    "merchantUsers.email": "Email",
    "merchantUsers.role_id": "Role",
    "merchantUsers.merchant_id": "Merchant company",
    "merchantUsers.activeFilters": "Active Filters:",
    "merchantUsers.merchantCompany": "Merchant Company",
    "merchantUsers.deleteMerchantUser": "Delete Merchant User",
    "merchantUsers.confirmModalDelMsg": "Are you sure to delete merchant user data with email equal {email}?",
    "merchantUsers.isSuperAdmin": "Is Super Admin",

    "serviceAlias.addBtn": "Create New Service",
    "serviceAlias.filterBtn": "Filter",
    "serviceAlias.activeFilters": "Active Filters:",
    'serviceAlias.addMerchantTitle': "Create service",
    'serviceAlias.editMerchantTitle': "Edit service",
    "serviceAlias.serviceName": "Service name",
    "serviceAlias.email": "Email",
    "serviceAlias.createdAt": "Service Created",
    "serviceAlias.updatedAt": "Last Modified",
    "serviceAlias.action": "Action",
    "serviceAlias.totalsSummary": "Showing {from} to {to} of {total} results",
    "serviceAlias.service_name": "Service name",
    "serviceAlias.service_code": "Service code",
    "serviceAlias.is_marketplace": "Is marketplace",
    "serviceAlias.active": "Active",
    "serviceAlias.merchants": "merchants",
    "serviceAlias.serviceCode": "Service Code",
    "serviceAlias.serviceName": "Service Name",
    "serviceAlias.isForSubscribe": "Is For Subscribe",
    "serviceAlias.deleteServiceAlias": "Delete Service Alias",
    "serviceAlias.confirmModalDelMsg": "Are you sure to delete user data with code equal {code}?",

    "servicePlan.addBtn": "Create New Service plan",
    "servicePlan.filterBtn": "Filter",
    "servicePlan.activeFilters": "Active Filters:",
    'servicePlan.addMerchantTitle': "Create service plan",
    'servicePlan.editMerchantTitle': "Edit service plan",
    "servicePlan.planName": "Plan Name",
    "servicePlan.serviceId": "Service Alias",
    "servicePlan.planRange": "Plan Range",
    "servicePlan.planRangeUnit": "Plan Range Unit",
    "servicePlan.createdAt": "Plan Created",
    "servicePlan.updatedAt": "Last Modified",
    "servicePlan.action": "Action",
    "servicePlan.totalsSummary": "Showing {from} to {to} of {total} results",
    "servicePlan.service_name": "Service name",
    "servicePlan.service_code": "Service code",
    "servicePlan.active": "Active",
    "servicePlan.planRangeUnit.0": "year",
    "servicePlan.planRangeUnit.1": "month",
    "servicePlan.planRangeUnit.2": "day",
    "servicePlan.plan_name": "Plan name",
    "servicePlan.deleteServicePlan": "Delete Service Plan",
    "servicePlan.confirmModalDelMsg": "Are you sure to delete plan data with plan name equal {planName}?",

    "merchantService.addBtn": "Create New Merchant Service",
    "merchantService.filterBtn": "Filter",
    "merchantService.activeFilters": "Active Filters:",
    'merchantService.addMerchantTitle': "Create merchant service",
    'merchantService.editMerchantTitle': "Edit merchant service",
    "merchantService.serviceName": "service Name",
    "merchantService.email": "Email",
    "merchantService.createdAt": "Service Created",
    "merchantService.updatedAt": "Last Modified",
    "merchantService.action": "Action",
    "merchantService.totalsSummary": "Showing {from} to {to} of {total} results",
    "merchantService.entityId": "Entity Id",
    "merchantService.merchantId": "Merchant Name",
    "merchantService.serviceId": "Service Alias",
    "merchantService.servicePlanId": "Service Plan ",
    "merchantService.planStartDate": "Plan Start Date ",
    "merchantService.planEndDate": "Plan End Date ",
    "merchantService.planExtendDate": "Plan Extend Date ",
    "merchantService.active": "Active",
    "merchantService.service_plan_id": "Service plan",
    "merchantService.service_id": "Service alias",
    "merchantService.merchant_id": "Merchant company",
    "merchantService.deleteMerchantService": "Delete Merchant Service",
    "merchantService.confirmModalDelMsg": "Are you sure to delete merchant service data with entityId equal {entityId}?",

    "merchantServiceInstance.addBtn": "Create New Service Instance",
    "merchantServiceInstance.filterBtn": "Filter",
    "merchantServiceInstance.activeFilters": "Active Filters:",
    'merchantServiceInstance.addMerchantTitle': "Create service instance",
    'merchantServiceInstance.editMerchantTitle': "Edit service instance",
    "merchantServiceInstance.createdAt": "Instance Created",
    "merchantServiceInstance.updatedAt": "Last Modified",
    "merchantServiceInstance.action": "Action",
    "merchantServiceInstance.totalsSummary": "Showing {from} to {to} of {total} results",
    "merchantServiceInstance.active": "Active",
    "merchantServiceInstance.instanceId": "Instance Id",
    "merchantServiceInstance.serviceId": "Merchant Service",
    "merchantServiceInstance.serviceStartDate": "Service Start Date",
    "merchantServiceInstance.serviceEndDate": "Service End Date",
    "merchantServiceInstance.confCode": "Conf Code",
    "merchantServiceInstance.confName": "Conf Name",
    "merchantServiceInstance.secureKey": "Secure Key",
    "merchantServiceInstance.apiGateway": "Api Gateway",
    "merchantServiceInstance.frontendApiGateway": "Frontend Api Gateway",
    "merchantServiceInstance.service_id": "Merchant Service Id",
    "merchantServiceInstance.conf_code": "Conf code",
    "merchantServiceInstance.conf_name": "Conf name",
    "merchantServiceInstance.api_gateway": "Api gateway",
    "merchantServiceInstance.deleteMerchantServiceInstance": "Delete Instance",
    "merchantServiceInstance.confirmModalDelMsg": "Are you sure to delete instance data with instance id equal {instanceId}?",

    "merchantsVendor.addBtn": "Create New Service Vendor",
    "merchantsVendor.filterBtn": "Filter",
    "merchantsVendor.activeFilters": "Active Filters:",
    'merchantsVendor.addMerchantTitle': "Create service vendor",
    'merchantsVendor.editMerchantTitle': "Edit service vendor",
    "merchantsVendor.createdAt": "Instance Created",
    "merchantsVendor.updatedAt": "Last Modified",
    "merchantsVendor.action": "Action",
    "merchantsVendor.totalsSummary": "Showing {from} to {to} of {total} results",
    "merchantsVendor.active": "Active",
    "merchantsVendor.instanceId": "Instance Id",
    "merchantsVendor.serviceId": "Merchant Service",
    "merchantsVendor.serviceStartDate": "Service Start Date",
    "merchantsVendor.serviceEndDate": "Service End Date",
    "merchantsVendor.vendorId": "Vendor Id",
    "merchantsVendor.vendorCode": "Vendor Code",
    "merchantsVendor.vendorName": "Vendor Name",
    "merchantsVendor.vendorContactEmail": "Vendor Contact Email",
    "merchantsVendor.vendorContactPhone": "Vendor Contact Phone",
    "merchantsVendor.frontendApiGateway": "Frontend Api Gateway",
    "merchantsVendor.vendorMerchantId": "Vendor Merchant",
    "merchantsVendor.merchantId": "Merchant",
    "merchantsVendor.vendor_merchant_id": "Vendor merchant",
    "merchantsVendor.merchant_id": "merchant",
    "merchantsVendor.vendor_name": "Vendor name",
    "merchantsVendor.vendor_code": "Vendor code",
    "merchantsVendor.deleteMerchantServiceInstance": "Delete Instance",
    "merchantsVendor.confirmModalDelMsg": "Are you sure to delete vendor data with instance id equal {instanceId}?",

    "adminRole.addBtn": "Create New Role",
    "adminRole.filterBtn": "Filter",
    "adminRole.activeFilters": "Active Filters:",
    'adminRole.addMerchantTitle': "Create role",
    'adminRole.editMerchantTitle': "Edit role",
    "adminRole.createdAt": "Service Created",
    "adminRole.updatedAt": "Last Modified",
    "adminRole.action": "Action",
    "adminRole.totalsSummary": "Showing {from} to {to} of {total} results",
    "adminRole.entityId": "Entity Id",
    "adminRole.active": "Active",
    "adminRole.roleName": "Role Name",
    "adminRole.permission": "Permission",
    "adminRole.permissionTitle": "{roleName} permission",
    "adminRole.role_name": "Role name",
    "adminRole.deleteRole": "Delete Role",
    "adminRole.confirmModalDelMsg": "Are you sure to delete role data with role name equal {roleName}?",

    "systemOperationLog.filterBtn": "Filter",
    "systemOperationLog.activeFilters": "Active Filters:",
    "systemOperationLog.Username": "User Name",
    "systemOperationLog.UserRoleName": "User Role Name",
    "systemOperationLog.ActionName": "Action Name",
    "systemOperationLog.IsSuccess": "Is Success",
    "systemOperationLog.CreatedAt": "Created At",
    "systemOperationLog.UpdatedAt": "Updated At",
    "systemOperationLog.Id": "Id",
    "systemOperationLog.viewMore": "View More",
    "systemOperationLog.action": "Action",
    "systemOperationLog.ActionContent": "Action Content",
    "systemOperationLog.totalsSummary": "Showing {from} to {to} of {total} results",

    "merchantDetail.overView": "Over View",
    "merchantDetail.serviceInstance": "Service Instance",
    "merchantDetail.userManagement": "User Management",
    "merchantDetail.MerchantService": "Merchant Service",
    "merchantDetail.merchantVendor": "Merchant Vendor",
    "merchantDetail.back": "Back",

    "profile.editProfile": "Edit My Profile",
    "profile.merchantId": "Merchant Id",
    "profile.userId": "User Id",
    "profile.timeZone": "Time Zone",
    "profile.save": "Save",
    "profile.firstName": "First Name",
    "profile.lastName": "Last Name",
    "profile.userEmail": "User Email",
    "profile.saveProfileSuccess": "Save profile  success",
    "settings.settings": "Settings",
    "settings.profile": "Profile",
    "settings.security": "Security",
    "setting.passwordNotMatch":"The new password that you entered do not match!",

    "frontendUsers.users": "Users",
    "frontendUsers.userEmail": "Email",
    "frontendUsers.email": "Email",
    "frontendUsers.createdAt": "Account Created",
    "frontendUsers.updatedAt": "Last Modified",
    "frontendUsers.action": "Action",
    'frontendUsers.addUserTitle': "Invite User",
    'frontendUsers.editUserTitle': "Edit User",
    "frontendUsers.addBtn": "Add one user",
    "frontendUsers.totalsSummary": "Showing {from} to {to} of {total} results",
    "frontendUsers.filterBtn": "Filter",
    "frontendUsers.first_name": "Firstname",
    "frontendUsers.last_name": "Lastname",
    "frontendUsers.user_email": "Email",
    "frontendUsers.activeFilters": "Active Filters:",
    "frontendUsers.merchantCompany": "Merchant Company",
    "frontendUsers.deleteMerchantUser": "Delete Merchant User",
    "frontendUsers.confirmModalDelMsg": "Are you sure to delete merchant user data with email equal {email}?",

}
export {
    en_US
}   