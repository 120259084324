import { useEffect, useMemo } from "react";

const RouterTitle = props => {

    const { title, children } = props;

    useEffect(() => {
        document.title = ` ${title} | e-Com365 admin portal`;
    }, [title]);

    return children
}

export default RouterTitle;